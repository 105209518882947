var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vodal',{staticClass:"vodal vodal-dialogCustom",attrs:{"show":_vm.isShowing,"animation":"zoom","closeButton":false},on:{"hide":() => {
      _vm.isShowing = false;
      this.$emit('hide');
    }}},[_c('b-card',{staticClass:"editorCard",staticStyle:{"margin":"0px"},attrs:{"no-body":""}},[_c('b-card-header',[_c('i',{class:_vm.icon}),_vm._v("\n      "+_vm._s(_vm.modalTitle)+"\n      "),_c('div',{staticClass:"card-header-actions",staticStyle:{"margin-left":"10px"}},[(_vm.closeButton == true)?_c('b-button',{staticClass:"mr-1 btn-brand",attrs:{"size":"sm","variant":"danger"},on:{"click":() => {
              _vm.isShowing = false;
              this.$emit('hide');
            }}},[_c('i',{staticClass:"fa fa-remove"})]):_vm._e()],1)]),_vm._v(" "),_c('b-card-body',{staticClass:"editorCardBody"},[_c('fullscreen',{ref:"fullscreen"},[(_vm.isFullScreen)?_c('b-button',{staticClass:"closeButton",attrs:{"size":"sm"},on:{"click":_vm.closeFullScreen}},[_c('i',{staticClass:"fa fa-remove"})]):_vm._e(),_vm._v(" "),(_vm.options != null)?_c('tui-image-editor',{ref:"imageEditor",attrs:{"include-ui":_vm.useDefaultUI,"options":_vm.options}}):_vm._e()],1)],1),_vm._v(" "),_c('b-card-footer',{staticClass:"text-right"},[_c('b-button',{class:_vm.button2Class,attrs:{"size":"sm"},on:{"click":function($event){return _vm.click2()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("saveAndUpload")))])]),_vm._v(" "),_c('i',[_vm._v(" | ")]),_vm._v(" "),_c('b-button',{class:_vm.button1Class,attrs:{"size":"sm"},on:{"click":function($event){return _vm.click1()}}},[_c('span',[_vm._v(_vm._s(this.$t("fullScreen")))])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }