/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
    let temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
function isImageFile(file) {
  if (file == null) {
    return false;
  }
  if (file['type'] === 'image/jpeg' || file['type'] === 'image/bmp'
    || file['type'] === 'image/gif'
    || file['type'] === 'image/x-icon'
    || file['type'] === 'image/png'
    || file['type'] === 'image/webp') {
    return true
  }
  return false;
}
function isVideoFile(file) {
  if (file['type'] === 'video/x-flv' || file['type'] === 'video/mp4'
    || file['type'] === 'video/MP2T'
    || file['type'] === 'video/3gpp'
    || file['type'] === 'video/quicktime'
    || file['type'] === 'video/x-msvideo') {
    return true
  }
  return false;
}
function copyObjects(source, destination) {
  let destinationKeys = Object.keys(destination);
  let sourceKeys = Object.keys(source);
  destinationKeys.forEach((key) => {
    if (sourceKeys.includes(key)) {
      source[key] = destination[key];
    }
  });
  return source;
}

export default { shuffleArray, asyncForEach, isImageFile, isVideoFile, copyObjects }