<template>
  <vodal
    :show="isShowing"
    animation="zoom"
    class="vodal vodal-dialogCustom"
    @hide="
      () => {
        isShowing = false;
        this.$emit('hide');
      }
    "
    :closeButton="false"
  >
    <b-card no-body class="editorCard" style="margin: 0px">
      <b-card-header>
        <i :class="icon"></i>
        {{ modalTitle }}
        <div class="card-header-actions" style="margin-left: 10px">
          <b-button
            size="sm"
            class="mr-1 btn-brand"
            variant="danger"
            v-if="closeButton == true"
            @click="
              () => {
                isShowing = false;
                this.$emit('hide');
              }
            "
          >
            <i class="fa fa-remove"></i>
          </b-button>
        </div>
      </b-card-header>
      <b-card-body class="editorCardBody">
        <fullscreen ref="fullscreen">
          <b-button
            v-if="isFullScreen"
            size="sm"
            class="closeButton"
            @click="closeFullScreen"
          >
            <i class="fa fa-remove"></i>
          </b-button>
          <tui-image-editor
            ref="imageEditor"
            v-if="options != null"
            :include-ui="useDefaultUI"
            :options="options"
          ></tui-image-editor>
        </fullscreen>
      </b-card-body>
      <b-card-footer class="text-right">
        <b-button size="sm" :class="button2Class" @click="click2()">
          <span>{{ $t("saveAndUpload") }}</span>
        </b-button>
        <i> | </i>
        <b-button size="sm" :class="button1Class" @click="click1()">
          <span>{{ this.$t("fullScreen") }}</span>
        </b-button>
      </b-card-footer>
    </b-card>
  </vodal>
</template>
<script>
import "tui-color-picker/dist/tui-color-picker.css";
import "tui-color-picker/dist/tui-color-picker.js";
import "../../../_photo-editor-module/svg/icon-a.svg";
import "../../../_photo-editor-module/svg/icon-b.svg";
import "../../../_photo-editor-module/svg/icon-c.svg";
import "../../../_photo-editor-module/svg/icon-d.svg";
import "../../../_photo-editor-module/tui-image-editor.css";
import { ImageEditor } from "../../../_photo-editor-module/vue-interface/core/toastui-vue-image-editor";
import lang from "../../lang/translations/photo-editor/tr.json";
import toastHelper from "../../shared/toast-helper";
import generatorsHelper from "../../shared/utils/generators-helper";

export default {
  name: "PhotoEditorModal",
  components: {
    "tui-image-editor": ImageEditor,
  },
  props: {
    modalTitle: String,
    fileModel: { type: Object, required: true },
    show: { type: Boolean, default: false },
    button1Class: { type: String, default: "btn-primary" },
    button2Class: { type: String, default: "btn-success" },
    icon: { type: String, default: "fa fa-edit mr-1" },
    cropIds: { type: Array, default: null },
    validHeight: { type: Number },
    validWidth: { type: Number },
    closeButton: { type: Boolean, default: true },
    activeMenu: {
      type: Array,
      default: () => {
        return [
          "flip",
          "resize",
          "draw",
          "shape",
          "icon",
          "text",
          "mask",
          "filter",
        ];
      },
    },
  },
  data() {
    return {
      useDefaultUI: true,
      options: null,
      isFullScreen: false,
      isShowing: this.show,
    };
  },
  watch: {
    change() {},
    show() {
      var locale = lang;
      this.isShowing = this.show;
      let fileName = generatorsHelper.random(1000, 9999);
      if (this.fileModel.title != "" && this.fileModel.title != null) {
        fileName = this.fileModel.title;
      }
      let fileAddress = "";
      if (this.fileModel.localFile != null) {
        fileAddress = this.fileModel.localFile;
      } else if (this.fileModel.localCoverFile != null) {
        fileAddress = this.fileModel.localCoverFile;
      } else if (this.fileModel.onlineCoverFile != null) {
        fileAddress = this.fileModel.onlineCoverFile.fileName;
      } else if (this.fileModel.onlineFile != null) {
        fileAddress = this.fileModel.onlineFile.fileName;
      }
      // according to modal size in css section get 70% of whole modal size for canvas:
      const modalWidth = 1000;
      const modalHeight = 700;
      const maxHeight = (modalHeight / this.validHeight) * this.validHeight;
      const maxWidth = (modalWidth / this.validWidth) * this.validWidth;
      // according to modal size in css section
      this.options = {
        includeUI: {
          loadImage: {
            path: fileAddress,
            name: fileName,
          },
          menu: this.activeMenu,
          initMenu: "resize",
          locale: locale,
          menuBarPosition: "left",
          cropActiveButtons: this.cropIds,
          callBack: (command) => this.callBack(command),
        },
        // for tui-image-editor component's "options" prop
        cssMaxWidth: 500,
        cssMaxHeight: 500,
        height: this.validHeight,
        width: this.validWidth,
      };
      if (this.show == false) {
        this.options = null;
      }
    },
    fileModel: {
      handler() {},
      deep: true,
    },
  },
  methods: {
    callBack(command) {
      switch (command) {
        case "cropSizeError":
          toastHelper.error(this.$t("cropImageSizeError"));
          break;
      }
    },
    click1() {
      this.$refs["fullscreen"].toggle();
      this.isFullScreen = true;
    },
    closeFullScreen() {
      this.isFullScreen = false;
      this.$refs.fullscreen.exit();
    },
    click2() {
      const saveOption = {
        format: "jpeg",
        quality: 0.7,
      };
      let base64 = this.$refs.imageEditor.invoke("toDataURL", saveOption);
      this.$emit("onSave", base64);
      this.isShowing = false;
    },
  },
  mounted() {
    this.$nextTick(function () {});
  },
};
</script>
<style>
.vodal-dialogCustom .vodal-dialog {
  padding: 0px;
  width: 1000px !important;
  height: 700px !important;
}
.editorCard {
  margin: 0px;
  height: 100% !important;
  width: 100% !important;
}
.editorCardBody > div:first-of-type {
  height: 100% !important;
  width: 100% !important;
}
.editorCardBody {
  padding: 0px !important;
}
.tui-image-editor-header {
  display: none !important;
}
.color-picker-control {
  height: 270px !important;
}
.tui-image-editor-wrap {
  overflow: hidden !important;
}
.closeButton {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background-color: beige;
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 1000;
  display: inline;
}
</style>
